import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
// import BlogRoll from '../components/BlogRoll'
import Banners from '../components/Banners'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import sig from '../img/signature.png'
import nzef from '../img/nzef.png'
import teamgbr from '../img/team-gbr.png'


export const IndexPageTemplate = ({
  banners,
  mainpitch,
  intro,
}) => (
  <div>
    <div style={{
      maxWidth: '1450px',
      margin: 'auto'
    }}>
      <Banners bannerItems={banners} />
    </div>
    <section className="section lead-home content">
      <div className="container">
        <div className="columns">
          <div className="column is-1 is-offset-1">
            <img src={teamgbr} alt="Team GBR" />
          </div>
          <div className="column is-8">
            <div className="tile">
              {/* <h1 className="title">{mainpitch.title}</h1> */}
            </div>
            <div className="tile" style={{ marginBottom: '1em', marginTop: '1em', textAlign: `center`}}>
              <p className="subtitle" dangerouslySetInnerHTML={{ __html: mainpitch.description }} />
            </div>
            <div className="subtext">
              <p>{mainpitch.continued}</p>
            </div>
          </div>
          <div className="column is-2">
            <img src={nzef} alt="New Zealand Equestrian" />
          </div>
        </div>
      </div>
    </section>
    <section className="content" style={{marginBottom: `0`}}>
      <div className="container">
        <div className="columns">
          <div className="column is-5 is-offset-1">
            <div className="intro">
              <h2 className="title is-2 has-padding-top-40" dangerouslySetInnerHTML={{ __html: intro.heading }}></h2>
              <div dangerouslySetInnerHTML={{ __html: intro.description }}></div>
              <img style={{maxHeight: `100px`}} className="has-margin-top-20 has-margin-bottom-40" src={sig} alt="Dr. Andre Buthe signature" />
            </div>
          </div>
          <div className="column is-4 is-offset-1">
            <div className="has-padding-left-50 has-padding-right-50" style={{display: `flex`,flexDirection: `column`, height: `100%`,justifyContent:`end`,}}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: intro.image,
                  alt: `${intro.heading}`,
                }}
                />
              </div>
            </div>
          </div>
        </div>
    </section>
    <section className="section services-home">
      <div className="container">
          <div className="columns is-multiline">
            <div className="column content is-10 is-offset-1">
            <div dangerouslySetInnerHTML={{__html:intro.blurbsIntro}}></div>
          </div>
          <Features gridItems={intro.blurbs} />
        </div>
      </div>
    </section>
  </div>
)

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <IndexPageTemplate
        banners={frontmatter.banners}
        mainpitch={frontmatter.mainpitch}
        intro={frontmatter.intro}
      />
    </Layout>
  ) 
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        banners {
          image {
            childImageSharp {
              fluid(maxWidth: 1450, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heading
          link
          bgcolour
        }
        mainpitch {
          title
          description
          continued
        }
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            link
            text
          }
          blurbsIntro
          heading
          description
          image {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
      }
    }
  }
`
