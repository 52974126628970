import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const FeatureGrid = ({ gridItems }) => (
  <>
    {gridItems.map(item => (
      <div key={item.title} className="column">
        <section
        className="section columns"
        style = {{}}
        >
          <div className="column">
            <Link to={item.link}>
              <div className="title" style={{
                background: `url(` + item.image.childImageSharp.fluid.src + `) no-repeat`,
                backgroundSize: `cover`,
                backgroundPosition: `center bottom`,
                height: `200px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `end`,
              }}>
                <h3 style={{
                  background: `rgba(0,0,0,.7)`,
                  color: `white`,
                  width: `100%`,
                  paddingTop: `.7rem`,
                  paddingBottom: `.5rem`,
                  paddingLeft: `1rem`,
                  paddingRight: `1rem`,
                  fontSize: `22px`,
                }}>{item.title}</h3>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            </Link>
          </div>
        </section>
      </div>
    ))}
    </>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default FeatureGrid
