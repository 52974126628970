import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Section = styled.section`
  background-image: url(${({ background }) => background});
`

const Banners = ({ bannerItems }) => (

  <div>
    {bannerItems.map(item => (
      <div
        key={item.heading}
      className="banner-wrapper"
      style={{
        backgroundColor: `${item.bgcolour}`,
      }}
      >
        <Section background={!!item.image.childImageSharp ? item.image.childImageSharp.fluid.src : item.image}
          className="full-width-image margin-top-0 banner-section"
      style={{
        backgroundColor: `${item.bgcolour}`,
        position: `relative`,
        height: `500px`,
  }}
  >

    </Section>
  </div>
    ))}
  </div>
)


export default Banners
